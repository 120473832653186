<template>
  <AppRow :permissions="['USERS_ADMINISTRATION', 'WITHDRAW_ADMINISTRATION', 'PD_SUPPORT']">
    <template v-slot="{ profile }">
      <p style="display: none">
        {{ profile }}
      </p>

      <!-- Основная информация -->
      <v-row class="ma-sm-4">
        <v-col cols="12">
          <ApolloQuery
            :query="require('../graphql/queries/getUserInfo.gql')"
            :update="data => user = data"
            :variables="{
              userId,
            }"
          >
            <template v-slot="{ result: { error: userError, data: userData }, isLoading: userIsLoading }">
              <!-- Error -->
              <AppNotification
                v-if="userError"
                :message="userError.message"
              />

              <!-- Loading -->
              <v-card
                v-else-if="userIsLoading === 1"
                :loading="userIsLoading === 1"
                flat
              />

              <!-- Result -->
              <v-card
                v-else-if="userData && userData.getUserInfo"
                flat
              >
                <v-card-text v-if="userData">
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-list-item
                        v-for="item of items"
                        :key="item.value"
                        two-line
                      >
                        <v-list-item-content>
                          <v-list-item-title>{{ item.text }}</v-list-item-title>
                          <v-list-item-subtitle v-if="item.value === 'email'">
                            <v-chip v-if="userData.getUserInfo.emailVerified">
                              <v-icon left>
                                mdi-check
                              </v-icon>
                              {{ userData.getUserInfo[item.value] }}
                            </v-chip>
                            <template v-else>
                              {{ userData.getUserInfo[item.value] || 'Неизвестно' }}
                            </template>
                          </v-list-item-subtitle>
                          <div
                            v-else
                            class="f"
                          >
                            <v-list-item-subtitle v-if="item.value === 'createdAt'">
                              {{ userData.getUserInfo[item.value] | moment('DD.MM.YYYY, HH:mm') }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-else>
                              {{ userData.getUserInfo[item.value] || 'Неизвестно' }}
                            </v-list-item-subtitle>

                            <v-list-item-subtitle v-if="item.value === 'username'">
                              <ApolloMutation
                                :mutation="require('../graphql/mutations/adminChangeUsername.gql')"
                                :refetch-queries="refetchQueriesAfterMyMutation"
                              >
                                <template v-slot="{ mutate, loading, error: changeError }">
                                  <v-dialog
                                    v-model="dialogUserName"
                                    max-width="500px"
                                    persistent
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        v-bind="attrs"
                                        icon
                                        v-on="on"
                                      >
                                        <v-icon>mdi-pencil</v-icon>
                                      </v-btn>
                                    </template>

                                    <v-card :loading="loading">
                                      <v-card-title>
                                        <span class="text-h5">Изменить имя</span>
                                      </v-card-title>

                                      <v-card-text>
                                        <v-container>
                                          <v-row>
                                            <v-text-field
                                              v-model="username"
                                              type="text"
                                              label="Username"
                                              flat
                                              solo
                                              background-color="#464646"
                                            />
                                          </v-row>
                                        </v-container>
                                      </v-card-text>

                                      <v-card-actions>
                                        <v-spacer />

                                        <v-btn
                                          color="primary"
                                          text
                                          @click="dialogUserName = false"
                                        >
                                          Отмена
                                        </v-btn>
                                        <v-btn
                                          color="primary"
                                          text
                                          @click="mutate({
                                            variables:
                                              {
                                                id: userId,
                                                username
                                              }
                                          })"
                                        >
                                          Сохранить
                                        </v-btn>
                                      </v-card-actions>
                                    </v-card>
                                  </v-dialog>
                                  <AppNotification
                                    v-if="changeError"
                                    :icon-disabled="true"
                                    :message="changeError.message"
                                  />
                                </template>
                              </ApolloMutation>
                            </v-list-item-subtitle>
                          </div>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <ApolloMutation
                          :mutation="require('../graphql/mutations/resetUserPass.gql')"
                          :update="newPass"
                        >
                          <template v-slot="{ mutate, loading }">
                            <v-row>
                              <v-col>
                                <v-card-text>{{ pass }}</v-card-text>
                              </v-col>
                              <v-col style="display: flex">
                                <v-btn
                                  :color="userData.getUserInfo.role === 'BANNED' ? 'primary' : 'error'"
                                  :loading="loading"
                                  style="margin: auto"
                                  class="text-none"
                                  depressed
                                  height="36"
                                  small
                                  @click="mutate({ variables: { userId } })"
                                >
                                  Сбросить пароль
                                </v-btn>
                              </v-col>
                            </v-row>
                          </template>
                        </ApolloMutation>
                      </v-list-item>
                      <v-list-item>
                        <ApolloMutation
                          :mutation="require('../graphql/mutations/resetTwoFA.gql')"
                          :update="update2faStatus"
                        >
                          <template v-slot="{ mutate, loading }">
                            <v-row>
                              <v-col>
                                <v-card-title>
                                  2FA
                                </v-card-title>
                                <v-card-text>{{ getTwoFaStatus }}</v-card-text>
                              </v-col>
                              <v-col style="display: flex">
                                <v-btn
                                  :color="status2fa !== 'NOT_SET' ? 'error' : 'primary'"
                                  :loading="loading"
                                  style="margin: auto"
                                  class="text-none"
                                  :disabled="status2fa === 'NOT_SET'"
                                  depressed
                                  height="36"
                                  small
                                  @click="mutate({ variables: { userId: `${userId}` } })"
                                >
                                  Сбросить 2FA
                                </v-btn>
                              </v-col>
                            </v-row>
                          </template>
                        </ApolloMutation>
                      </v-list-item>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-list-item
                        v-for="item of secondItems"
                        :key="item.value"
                        two-line
                      >
                        <v-list-item-content>
                          <v-list-item-title>{{ item.text }}</v-list-item-title>
                          <v-list-item-subtitle v-if="item.value === 'socialAccounts'">
                            <a
                              v-for="link of links"
                              :key="link.value"
                              :href="userData.getUserInfo[item.value][link.value] ? `${link.link}${userData.getUserInfo[item.value][link.value]}` : ''"
                              :style="{ pointerEvents: userData.getUserInfo[item.value][link.value] ? '' : 'none' }"
                              target="_blank"
                            >
                              <v-icon :disabled="!userData.getUserInfo[item.value][link.value]">{{ link.icon }}</v-icon>
                            </a>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-else-if="item.value === 'meta'">
                            <p>{{ userData.getUserInfo[item.value] }}</p>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-else-if="item.value === 'tags'">
                            <ApolloMutation
                              :mutation="require('../graphql/mutations/adminRemoveUserTag.gql')"
                              :refetch-queries="refetchQueriesAfterMyMutation"
                            >
                              <template v-slot="{ mutate }">
                                <v-chip-group
                                  column
                                >
                                  <v-chip
                                    v-for="tag of userData.getUserInfo[item.value].manualTags"
                                    :key="tag.id"
                                    :color="tag.category === 'FRAUD' ? 'purple' : tag.category === 'PAYOUT' ? 'blue' : 'orange'"
                                    class="ma-2"
                                    close
                                    @click:close="mutate({ variables: { userId, tagId: tag.id } })"
                                  >
                                    {{ tag.name }}
                                  </v-chip>
                                  <v-chip
                                    color="grey"
                                    class="ma-2"
                                    append-icon="mdi-star"
                                    @click="tagsWindowOpened = true"
                                  >
                                    <v-icon
                                      small
                                      left
                                    >
                                      mdi-plus
                                    </v-icon>
                                    Добавить тег
                                  </v-chip>
                                </v-chip-group>
                              </template>
                            </ApolloMutation>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-else-if="item.value === 'kycVerified'">
                            <ApolloQuery
                              :query="require('../graphql/queries/getUserKycStatus.gql')"
                              :variables="{
                                userId,
                              }"
                            >
                              <template v-slot="{ result: { error: kycError, data: kycData } }">
                                <AppNotification
                                  v-if="kycError"
                                  :message="kycError.message"
                                />
                                <p v-if="kycData && kycData.getUserKycStatus">
                                  {{ kycData.getUserKycStatus.kycVerified ? 'KYC verified' : 'no KYC' }}
                                </p>
                                <ApolloMutation
                                  :mutation="require('../graphql/mutations/verifyUserKyc.gql')"
                                  :refetch-queries="refetchGetUserKycStatus"
                                >
                                  <template v-slot="{ mutate }">
                                    <v-btn
                                      :color="'primary'"
                                      :loading="loading"
                                      style="margin: auto"
                                      class="text-none"
                                      depressed
                                      height="36"
                                      small
                                      @click="mutate({ variables: { userId, verified: true } })"
                                    >
                                      Одобрить KYC
                                    </v-btn>
                                  </template>
                                </ApolloMutation>
                                <v-row v-if="kycData && kycData.getUserKycStatus">
                                  <template>
                                    <v-col
                                      v-for="docTUrl in kycData.getUserKycStatus.docs"
                                      :key="docTUrl"
                                    >
                                      <img
                                        :src="docTUrl"
                                        :style="{
                                          width: '50px',
                                        }"
                                        @click="upd(docTUrl)"
                                      >
                                    </v-col>
                                  </template>
                                </v-row>
                              </template>
                            </ApolloQuery>
                            <template>
                              <v-dialog
                                v-model="docOpened"
                                max-width="1000px"
                                persistent
                              >
                                <img
                                  :src="docUrl"
                                  :style="{
                                    width: '100%',
                                    display: 'block',
                                  }"
                                  @click="upd"
                                >
                              </v-dialog>
                            </template>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-else-if="item.value === 'scoreCounter'">
                            <ApolloQuery
                              :query="require('../graphql/queries/getAntifraudAccounts.gql')"
                              :variables="{
                                userId,
                              }"
                            >
                              <template v-slot="{ result: { error: scError, data: sc }, isLoading: scIsLoading }">
                                <AppNotification
                                  v-if="scError"
                                  :message="scError.message"
                                />

                                <!-- Loading -->
                                <v-card
                                  v-else-if="scIsLoading === 1"
                                  :loading="scIsLoading === 1"
                                  flat
                                />

                                <v-card
                                  v-else-if="sc && sc.getAntifraudAccounts"
                                  flat
                                >
                                  {{ sc.getAntifraudAccounts.length }}
                                  <v-dialog
                                    v-model="scDialog"
                                    max-width="900px"
                                    persistent
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        v-bind="attrs"
                                        class="ma-2"
                                        icon
                                        x-small
                                        v-on="on"
                                      >
                                        <v-icon>mdi-information-outline</v-icon>
                                      </v-btn>
                                    </template>
                                    <v-card :loading="loading">
                                      <v-card-title>
                                        <span class="text-h5">SC</span>
                                      </v-card-title>
                                      <v-card-text>
                                        <template>
                                          <v-data-table
                                            :headers="[{ text: 'UserId', value: 'userId', class: 'white--text text-subtitle-2' }]"
                                            :items="sc.getAntifraudAccounts"
                                            item-key="userId"
                                            show-expand
                                            class="elevation-1"
                                          >
                                            <template v-slot:[`item.userId`]="{ item: scItem }">
                                              {{ scItem.userId }}
                                              <v-btn
                                                class="ml-2 text-none"
                                                depressed
                                                height="36"
                                                small
                                                @click="goToUserFromSc(scItem.userId)"
                                              >
                                                Перейти к игроку
                                              </v-btn>
                                            </template>
                                            <template v-slot:expanded-item="{ headers: scHeaders, item: scItem }">
                                              <td
                                                :colspan="scHeaders.length"
                                                :style="{ padding: 0 }"
                                              >
                                                <v-simple-table>
                                                  <template v-slot:default>
                                                    <tbody>
                                                      <tr
                                                        v-for="scValue in scItem.relations"
                                                        :key="scValue.value"
                                                      >
                                                        <td>{{ scMap[scValue.type] }}</td>
                                                        <td>{{ scValue.value }}</td>
                                                      </tr>
                                                    </tbody>
                                                  </template>
                                                </v-simple-table>
                                              </td>
                                            </template>
                                          </v-data-table>
                                        </template>
                                      </v-card-text>
                                      <v-card-actions>
                                        <v-spacer />

                                        <v-btn
                                          color="primary"
                                          text
                                          @click="scDialog = false"
                                        >
                                          Закрыть
                                        </v-btn>
                                      </v-card-actions>
                                    </v-card>
                                  </v-dialog>
                                </v-card>
                                <AppNotification
                                  v-else
                                  message="Нет результата :("
                                />
                              </template>
                            </ApolloQuery>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-else>
                            {{ userData.getUserInfo[item.value] || 'Неизвестно' }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item-subtitle>
                        <ApolloMutation
                          :mutation="require('../graphql/mutations/transferUserBalance.gql')"
                          :refetch-queries="refetchQueriesAfterMyMutation"
                        >
                          <template v-slot="{ mutate, loading, error: changeError }">
                            <v-dialog
                              v-model="dialogTransfer"
                              max-width="500px"
                              persistent
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  :color="'teal'"
                                  class="ml-4 mt-3 mb-5 text-none"
                                  depressed
                                  height="36"
                                  small
                                  v-on="on"
                                >
                                  <v-icon>mdi-wallet</v-icon>
                                  <span class="ml-1">Трансфер баланса</span>
                                </v-btn>
                              </template>

                              <v-card :loading="loading">
                                <v-card-title>
                                  <span class="text-h5">Трансфер баланса</span>
                                </v-card-title>

                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-row>
                                        <v-col>
                                          <v-btn
                                            :color="varTransfer === 'get' ? 'primary' : ''"
                                            class="mb-5"
                                            style="width: 100%"
                                            @click="varTransfer = 'get'"
                                          >
                                            <span>Получить</span>
                                          </v-btn>
                                        </v-col>
                                        <v-col>
                                          <v-btn
                                            :color="varTransfer === 'set' ? 'primary' : ''"
                                            class="mb-5"
                                            style="width: 100%"
                                            @click="varTransfer = 'set'"
                                          >
                                            <span>Отправить</span>
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </v-row>
                                    <v-row>
                                      <v-text-field
                                        v-model="secondUserId"
                                        type="text"
                                        :label="varTransfer === 'get' ? 'ID отправителя' : 'ID получателя'"
                                        flat
                                        solo
                                        background-color="#464646"
                                      />
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer />

                                  <v-btn
                                    color="primary"
                                    text
                                    @click="dialogTransfer = false"
                                  >
                                    Отмена
                                  </v-btn>
                                  <v-btn
                                    color="primary"
                                    text
                                    @click="mutate({
                                      variables: varTransfer === 'get' ?
                                        {
                                          recipientId: userId,
                                          creditorId: Number(secondUserId),
                                        } :
                                        {
                                          recipientId: Number(secondUserId),
                                          creditorId: userId,
                                        }
                                    })"
                                  >
                                    Трансфер
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <AppNotification
                              v-if="changeError"
                              :icon-disabled="true"
                              :message="changeError.message"
                            />
                          </template>
                        </ApolloMutation>
                      </v-list-item-subtitle>
                    </v-col>
                  </v-row>
                  <v-row justify="start">
                    <div class="f fs">
                      <v-list-item-subtitle>
                        <ApolloMutation
                          :mutation="require('../graphql/mutations/changeUserData.gql')"
                          :refetch-queries="refetchQueriesAfterMyMutation"
                        >
                          <template v-slot="{ mutate, loading, error: changeError }">
                            <v-dialog
                              v-model="dialog"
                              max-width="500px"
                              persistent
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  :color="'primary'"
                                  class="ml-4 mb-5"
                                  v-on="on"
                                >
                                  <v-icon>mdi-pencil</v-icon>
                                  <span class="ml-1">Редактировать</span>
                                </v-btn>
                              </template>

                              <v-card :loading="loading">
                                <v-card-title>
                                  <span class="text-h5">Редактирование</span>
                                </v-card-title>

                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-text-field
                                        v-model="email"
                                        type="email"
                                        label="Email"
                                        flat
                                        solo
                                        background-color="#464646"
                                      />
                                    </v-row>
                                    <v-row>
                                      <v-text-field
                                        v-model="maxBet"
                                        type="text"
                                        label="Max Bet"
                                        flat
                                        solo
                                        background-color="#464646"
                                      />
                                    </v-row>
                                    <v-row>
                                      <v-select
                                        v-model="role"
                                        :items="roles"
                                        background-color="#464646"
                                        class="rounded-lg"
                                        flat
                                        hide-details="auto"
                                        label="Группа"
                                        solo
                                      />
                                    </v-row>
                                    <v-row class="mt-4">
                                      <v-select
                                        v-model="status"
                                        :items="statuses"
                                        background-color="#464646"
                                        class="rounded-lg"
                                        flat
                                        hide-details="auto"
                                        label="Status"
                                        solo
                                      />
                                    </v-row>
                                    <v-row class="mt-4">
                                      <v-textarea
                                        v-model="statusReason"
                                        type="Причина"
                                        label="Причина"
                                        flat
                                        solo
                                        background-color="#464646"
                                      />
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer />

                                  <v-btn
                                    color="primary"
                                    text
                                    @click="dialog = false"
                                  >
                                    Отмена
                                  </v-btn>
                                  <v-btn
                                    color="primary"
                                    text
                                    @click="mutate({
                                      variables:
                                        {
                                          id: userId,
                                          role,
                                          email,
                                          status,
                                          statusReason,
                                          maxBet: (maxBet !== undefined && maxBet !== null) ? +maxBet : null,
                                          balance: selectedPurseType
                                            ? [{
                                              purseType: selectedPurseType,
                                              amount: balanceReal,
                                              bonus: balanceBonus,
                                            }]
                                            : null
                                        }
                                    })"
                                  >
                                    Сохранить
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <AppNotification
                              v-if="changeError"
                              :icon-disabled="true"
                              :message="changeError.message"
                            />
                          </template>
                        </ApolloMutation>
                      </v-list-item-subtitle>
                    </div>
                  </v-row>
                  <!--Редактирование счета-->
                  <v-row justify="start">
                    <div class="f fs">
                      <v-list-item-subtitle>
                        <v-dialog
                          v-model="isChangeBalanceOpened"
                          max-width="500px"
                          persistent
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              :color="'primary'"
                              class="ml-4 mb-5"
                              v-on="on"
                            >
                              <v-icon>mdi-pencil</v-icon>
                              <span class="ml-1">Редактировать баланс</span>
                            </v-btn>
                          </template>

                          <!--todo-->
                          <v-card :loading="changeBalanceState.loading">
                            <v-card-title>
                              <span class="text-h5">Редактирование баланса</span>
                            </v-card-title>

                            <v-card-text>
                              <v-container>
                                <v-row class="mt-4">
                                  <v-select
                                    v-model="selectedPurseType"
                                    :items="purseTypes"
                                    background-color="#464646"
                                    class="rounded-lg"
                                    flat
                                    hide-details="auto"
                                    label="Кошелек"
                                    solo
                                    @change="changeSelectedPurseType"
                                  />
                                </v-row>
                                <v-row class="mt-4">
                                  <v-select
                                    v-model="selectedChangeBalanceType"
                                    :items="changeBalanceTypeItems"
                                    background-color="#464646"
                                    class="rounded-lg"
                                    flat
                                    hide-details="auto"
                                    label="Вариант"
                                    :disabled="selectedPurseType === null"
                                    solo
                                  />
                                </v-row>

                                <v-row
                                  v-if="selectedChangeBalanceType !== null"
                                  class="mt-4"
                                >
                                  <v-select
                                    v-model="selectedChangeManualBalanceType"
                                    :items="manualBalanceChangeItems"
                                    background-color="#464646"
                                    class="rounded-lg"
                                    flat
                                    hide-details="auto"
                                    label="Тип"
                                    solo
                                  />
                                </v-row>

                                <v-row class="mt-4">
                                  <v-textarea
                                    v-model="changeBalanceComment"
                                    type="Комментарий"
                                    label="Комментарий"
                                    flat
                                    solo
                                    background-color="#464646"
                                  />
                                </v-row>

                                <v-row
                                  v-if="selectedPurseType"
                                  class="mt-4"
                                >
                                  <v-col
                                    cols="12"
                                    sm="6"
                                  >
                                    <v-text-field
                                      v-model.number="balanceReal"
                                      hide-details="auto"
                                      label="Реальный баланс"
                                      disabled
                                    />
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="6"
                                  >
                                    <v-text-field
                                      v-model.number="balanceBonus"
                                      hide-details="auto"
                                      label="Бонусный баланс"
                                      disabled
                                    />
                                  </v-col>
                                </v-row>
                                <v-row v-if="selectedChangeBalanceType !== null">
                                  <v-col
                                    cols="12"
                                    sm="12"
                                  >
                                    <v-text-field
                                      v-model.number="newBalance"
                                      hide-details="auto"
                                      :label="`${selectedChangeBalanceType} на`"
                                    />
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer />

                              <v-btn
                                color="primary"
                                text
                                @click="isChangeBalanceOpened = false"
                              >
                                Отмена
                              </v-btn>

                              <v-btn
                                color="primary"
                                text
                                :disabled="!selectedChangeBalanceType || !selectedChangeManualBalanceType || !changeBalanceComment"
                                @click="balanceDecrement"
                              >
                                Сохранить
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <AppNotification
                          v-if="changeBalanceState.error"
                          :icon-disabled="true"
                          :message="changeBalanceState.error"
                        />
                      </v-list-item-subtitle>
                    </div>
                  </v-row>
                  <!--Добавление тега-->
                  <v-row justify="start">
                    <div class="f fs">
                      <v-list-item-subtitle>
                        <v-dialog
                          v-model="tagsWindowOpened"
                          max-width="500px"
                          persistent
                        >
                          <!--todo-->
                          <ApolloQuery
                            :query="require('../graphql/queries/getTags.gql')"
                            :variables="{}"
                          >
                            <template v-slot="{ result: { data: tagsData } }">
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">Добавление тега игроку</span>
                                </v-card-title>

                                <v-card-text v-if="tagsData">
                                  <v-container>
                                    <v-row class="mt-4">
                                      <v-select
                                        v-model="selectedTagId"
                                        :items="sortedTags"
                                        background-color="#464646"
                                        class="rounded-lg"
                                        flat
                                        hide-details="auto"
                                        label="Тег"
                                        solo
                                        multiple
                                        @change="handleTagsChange"
                                      >
                                        <template v-slot:item="{ item, on }">
                                          <v-list-item
                                            v-on="on"
                                          >
                                            <v-list-item-action>
                                              <v-checkbox
                                                :input-value="manualTags.map(tag => tag.id).includes(item.id)"
                                                :disabled="(manualTags.map(tag => tag.category).includes(item.category) && !manualTags.map(tag => tag.id).includes(item.id)) || tagsStagsStateIsChangingtate"
                                              />
                                            </v-list-item-action>

                                            <v-list-item-content>
                                              <v-list-item-title>
                                                <v-row
                                                  no-gutters
                                                  align="center"
                                                >
                                                  {{ item.text }}
                                                </v-row>
                                              </v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                        </template>
                                      </v-select>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer />

                                  <!-- <v-btn
                                    color="primary"
                                    text
                                    @click="tagsWindowOpened = false"
                                  >
                                    Отмена
                                  </v-btn> -->

                                  <v-btn
                                    color="primary"
                                    text
                                    @click="tagsWindowOpened = false"
                                  >
                                    Закрыть
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </ApolloQuery>
                        </v-dialog>
                      </v-list-item-subtitle>
                    </div>
                  </v-row>
                </v-card-text>
              </v-card>

              <!-- No result -->
              <AppNotification
                v-else
                message="Нет результата :("
              />
            </template>
          </ApolloQuery>
        </v-col>
      </v-row>

      <!-- Баланс -->
      <v-row class="ma-sm-4">
        <v-col
          class="text-center text-h5"
          cols="12"
        >
          Баланс
        </v-col>

        <v-col cols="12">
          <ApolloQuery
            :query="require('../graphql/queries/getUserInfo.gql')"
            :variables="{
              userId,
            }"
          >
            <template v-slot="{ result: { error: userError, data: userData }, isLoading: userIsLoading }">
              <!-- Error -->
              <AppNotification
                v-if="userError"
                :message="userError.message"
              />
              <!-- Result -->
              <v-row v-else-if="userIsLoading === 1" />

              <!-- Result -->
              <v-row v-else-if="userData && userData.getUserInfo.balance">
                <v-col cols="12">
                  <v-card
                    :loading="userIsLoading === 1"
                    class="px-4 pb-3"
                    flat
                  >
                    <v-simple-table v-if="userData">
                      <template v-slot:default>
                        <thead>
                        <tr>
                          <th>Currency</th>
                          <th>Amount</th>
                          <th>Bonus (Not bonus balance)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                          v-for="item of userData.getUserInfo.balance"
                          :key="item.purseType"
                        >
                          <td>{{ item.purseType }}</td>
                          <td>{{ item.amount }}</td>
                          <td>{{ item.bonus }}</td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </v-col>
              </v-row>

              <!-- No result -->
              <AppNotification
                v-else
                message="Нет результата :("
              />
            </template>
          </ApolloQuery>
        </v-col>
      </v-row>

      <!-- BetBy История ставок на матчи -->
      <v-row class="ma-sm-4">
        <v-col
          cols="12"
          sm="2"
        />
        <v-col
          class="text-center text-h5"
          cols="12"
          sm="8"
        >
          BetBy История ставок на матчи
        </v-col>
        <v-col
          cols="12"
          sm="2"
        >
          <v-btn
            color="primary"
            large
            block
            @click="getInfo('bets/betby')"
          >
            <v-icon class="mr-2">
              mdi-download
            </v-icon>
            Скачать
          </v-btn>
        </v-col>

        <v-col cols="12">
          <ApolloQuery
            :query="require('../graphql/queries/adminBetbyBetsHistory.gql')"
            :variables="{
              userId,
              skip: (bbetsPageIndex - 1) * bbetsItemsPerPage,
              limit: bbetsItemsPerPage,
            }"
          >
            <template v-slot="{ result: { error: bbetsError, data: bbetsData }, isLoading: bbetsIsLoading }">
              <!-- Error -->
              <AppNotification
                v-if="bbetsError"
                :message="bbetsError.message"
              />

              <!-- Result -->
              <v-card
                v-else-if="bbetsIsLoading === 1"
                :loading="bbetsIsLoading === 1"
                class="px-4 pb-3"
                flat
              />

              <!-- Result -->
              <v-card
                v-else-if="(bbetsData && bbetsData.adminBetbyBetsHistory)"
                class="px-4 pb-3"
                flat
              >
                <v-data-table
                  v-if="bbetsData"
                  :headers="bbetsHeaders"
                  :items="bbetsData.adminBetbyBetsHistory.items"
                  :items-per-page="bbetsItemsPerPage"
                  disable-sort
                  hide-default-footer
                >
                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ new Date(item.createdAt) | moment('DD.MM.YYYY, HH:mm') }}
                  </template>
                  <template v-slot:[`item.bets`]="{ item }">
                    {{
                      (item.bets && item.bets[0] && item.bets[0].title && item.bets.length === 1) ? item.bets[0].title : ''
                    }}
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <v-btn
                      class="text-none"
                      depressed
                      height="36"
                      large
                      @click="showBbetsModal(item.bets)"
                    >
                      Подробнее
                    </v-btn>
                  </template>
                </v-data-table>
                <div class="text-center pt-3">
                  <v-pagination
                    v-model="bbetsPageIndex"
                    :length="Math.ceil(bbetsData.adminBetbyBetsHistory.totalCount / bbetsItemsPerPage)"
                    :total-visible="9"
                  />
                  <v-select
                    v-model="bbetsItemsPerPage"
                    :items="[5, 50, 500]"
                    class="pa-4 pt-5"
                    label="Строк на странице"
                    @change="bbetsItemsPerPage"
                  />
                </div>
              </v-card>

              <!-- No result -->
              <AppNotification
                v-else
                message="Нет результата :("
              />
            </template>
          </ApolloQuery>
        </v-col>
      </v-row>

      <!-- История ставок на матчи -->
      <v-row class="ma-sm-4">
        <v-col
          cols="12"
          sm="2"
        />
        <v-col
          class="text-center text-h5"
          cols="12"
          sm="8"
        >
          История ставок на матчи
        </v-col>
        <v-col
          cols="12"
          sm="2"
        >
          <v-btn
            color="primary"
            large
            block
            @click="getInfo('bets/bet')"
          >
            <v-icon class="mr-2">
              mdi-download
            </v-icon>
            Скачать
          </v-btn>
        </v-col>

        <v-col cols="12">
          <ApolloQuery
            :query="require('../graphql/queries/adminBetsHistory.gql')"
            :variables="{
              userId,
              skip: (betsPageIndex - 1) * betsItemsPerPage,
              limit: betsItemsPerPage,
            }"
          >
            <template v-slot="{ result: { error: betsError, data: betsData }, isLoading: betsIsLoading }">
              <!-- Error -->
              <AppNotification
                v-if="betsError"
                :message="betsError.message"
              />

              <!-- Result -->
              <v-card
                v-else-if="betsIsLoading === 1"
                :loading="betsIsLoading === 1"
                class="px-4 pb-3"
                flat
              />

              <!-- Result -->
              <v-card
                v-else-if="betsData && betsData.adminBetsHistory"
                class="px-4 pb-3"
                flat
              >
                <v-data-table
                  v-if="betsData"
                  :headers="betsHeaders"
                  :items="betsData.adminBetsHistory.items"
                  :items-per-page="betsItemsPerPage"
                  disable-sort
                  hide-default-footer
                >
                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ new Date(item.createdAt) | moment('DD.MM.YYYY, HH:mm') }}
                  </template>
                  <template v-slot:[`item.bets`]="{ item }">
                    {{
                      (item.bets && item.bets[0] && item.bets[0].title && item.bets.length === 1) ? item.bets[0].title : ''
                    }}
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <v-btn
                      class="text-none"
                      depressed
                      height="36"
                      large
                      @click="showBetsModal(item.bets)"
                    >
                      Подробнее
                    </v-btn>
                  </template>
                </v-data-table>
                <div class="text-center pt-3">
                  <v-pagination
                    v-model="betsPageIndex"
                    :length="Math.ceil(betsData.adminBetsHistory.totalCount / betsItemsPerPage)"
                    :total-visible="9"
                  />
                  <v-select
                    v-model="betsItemsPerPage"
                    :items="[5, 50, 500]"
                    class="pa-4 pt-5"
                    label="Строк на странице"
                    @change="betsItemsPerPage"
                  />
                </div>
              </v-card>

              <!-- No result -->
              <AppNotification
                v-else
                message="Нет результата :("
              />
            </template>
          </ApolloQuery>
        </v-col>
      </v-row>

      <!-- Депозиты -->
      <v-row class="ma-sm-4">
        <v-col
          class="text-center text-h5"
          cols="12"
        >
          <v-row>
            <v-container style="margin: auto; width: fit-content">
              <v-row>
                <v-col style="width: fit-content;flex-grow: 0;margin: auto">
                  Депозиты
                </v-col>
                <v-col style="width: fit-content;flex-grow: 0;">
                  <ApolloMutation
                    :mutation="require('../graphql/mutations/addManualDeposit.gql')"
                    :refetch-queries="refetchDepositsAfterMyMutation"
                  >
                    <template v-slot="{ mutate, loading }">
                      <v-dialog
                        v-model="depositDialog"
                        max-width="500px"
                        persistent
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            class="ma-2"
                            icon
                            v-on="on"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>

                        <v-card :loading="loading">
                          <v-card-title>
                            <span class="text-h5">Добавление депозита</span>
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-row class="mt-4">
                                <v-select
                                  v-model="selectedPurseType"
                                  :items="purseTypes"
                                  background-color="#464646"
                                  class="rounded-lg"
                                  flat
                                  hide-details="auto"
                                  label="Кошелек"
                                  solo
                                  @change="changeSelectedPurseType"
                                />
                              </v-row>
                              <v-row v-if="selectedPurseType !== null">
                                <v-col
                                  cols="12"
                                  sm="6"
                                >
                                  <v-text-field
                                    v-model.number="depositAmount"
                                    hide-details="auto"
                                    label="Размер депозита"
                                  />
                                </v-col>
                              </v-row>
                              <v-row v-if="selectedPurseType !== null">
                                <v-col
                                  cols="12"
                                  sm="6"
                                >
                                  <v-text-field
                                    v-model="offerId"
                                    hide-details="auto"
                                    label="ID оффера"
                                  />
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer />

                            <v-btn
                              color="primary"
                              text
                              @click="depositDialog = false"
                            >
                              Отмена
                            </v-btn>
                            <v-btn
                              color="primary"
                              text
                              @click="mutate({
                                variables:
                                  {
                                    userId,
                                    purseType: selectedPurseType,
                                    amount: depositAmount,
                                    offerId: (offerId && offerId.trim() !== '') ? offerId.trim() : undefined,
                                  }
                              })"
                            >
                              Добавить
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </template>
                  </ApolloMutation>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="selectDepositStatus"
                :items="statusDeposit"
                background-color="#464646"
                class="rounded-lg"
                clearable
                flat
                hide-details="auto"
                item-text="text"
                item-value="key"
                label="Список статусов депозитов"
                solo
              />
            </v-col>
          </v-row>
          <ApolloQuery
            :query="require('../graphql/queries/getUserDeposits.gql')"
            :variables="selectDepositStatus ? {
              userId,
              status: +selectDepositStatus
            } : {
              userId,
            }"
          >
            <template v-slot="{ result: { error: userError, data: userDeposits }, isLoading: userIsLoading }">
              <!-- Error -->
              <AppNotification
                v-if="userError"
                :message="userError.message"
              />

              <!-- Result -->
              <v-card
                v-else-if="userIsLoading === 1"
                :loading="userIsLoading === 1"
                flat
              />

              <!-- Result -->
              <v-card
                v-else-if="userDeposits && userDeposits.getUserDeposits"
                flat
              >
                <v-data-table
                  v-if="userDeposits.getUserDeposits.history"
                  :headers="depositHeaders"
                  :items="userDeposits.getUserDeposits.history || []"
                  :items-per-page="5"
                >
                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ item.createdAt | moment('DD.MM.YYYY, HH:mm') }}
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    {{ initStatusDeposit(item.status) }}
                  </template>
                </v-data-table>
              </v-card>

              <!-- No result -->
              <AppNotification
                v-else
                message="Нет результата :("
              />
            </template>
          </ApolloQuery>
        </v-col>
      </v-row>

      <!-- Ручные бонусы -->
      <v-row class="ma-sm-4">
        <v-col
          class="text-center text-h5"
          cols="12"
        >
          <ApolloQuery :query="require('../graphql/queries/getManualBonusOptions.gql')">
            <template v-slot="{ result: { error: optionsError, data: options }, isLoading: optionsIsLoading }">
              <!-- Error -->
              <AppNotification
                v-if="optionsError"
                :message="optionsError.message"
              />
              <v-row>
                <v-container style="margin: auto; width: fit-content">
                  <v-row>
                    <v-col style="width: fit-content;flex-grow: 0;margin: auto">
                      Ручные бонусы
                    </v-col>
                    <v-col style="width: fit-content;flex-grow: 0;">
                      <ApolloMutation
                        :mutation="require('../graphql/mutations/createManualBonus.gql')"
                        :refetch-queries="refetchManualBonusAfterMyMutation"
                        :variables="{
                          userId,
                          amount: manualBonusAmount,
                          type: manualBonusType,
                          purseType: selectedPurseType,
                        }"
                      >
                        <template v-slot="{ mutate, loading }">
                          <v-dialog
                            v-model="manualBonusDialog"
                            max-width="500px"
                            persistent
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                class="ma-2"
                                icon
                                v-on="on"
                              >
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </template>

                            <v-card :loading="loading || optionsIsLoading">
                              <v-card-title>
                                <span class="text-h5">Добавление ручного бонуса</span>
                              </v-card-title>

                              <v-card-text>
                                <v-container>
                                  <v-row class="mt-4">
                                    <v-select
                                      v-model="selectedPurseType"
                                      :items="purseTypes"
                                      background-color="#464646"
                                      class="rounded-lg"
                                      flat
                                      hide-details="auto"
                                      label="Кошелек"
                                      solo
                                      @change="changeSelectedPurseType"
                                    />
                                  </v-row>
                                  <v-row v-if="selectedPurseType !== null">
                                    <v-col
                                      cols="12"
                                      sm="6"
                                    >
                                      <v-text-field
                                        v-model.number="manualBonusAmount"
                                        hide-details="auto"
                                        label="Размер бонуса"
                                      />
                                    </v-col>
                                    <v-col
                                      cols="12"
                                      sm="6"
                                    >
                                      <v-select
                                        v-model="manualBonusType"
                                        :items="options.getManualBonusOptions.filter((item) => !item.title.includes('POPUP')).map((item) => ({ text: item.title, key: item.id }))"
                                        background-color="#464646"
                                        class="rounded-lg"
                                        clearable
                                        flat
                                        hide-details="auto"
                                        item-text="text"
                                        item-value="key"
                                        label="Тип бонуса"
                                        solo
                                      />
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer />

                                <v-btn
                                  color="primary"
                                  text
                                  @click="manualBonusDialog = false"
                                >
                                  Отмена
                                </v-btn>
                                <v-btn
                                  color="primary"
                                  text
                                  @click="mutate()"
                                >
                                  Добавить
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </template>
                      </ApolloMutation>
                    </v-col>
                  </v-row>
                </v-container>
              </v-row>
              <ApolloQuery
                :query="require('../graphql/queries/getManualBonusHistory.gql')"
                :variables="{
                  userId,
                  skip: (manualBonusPageIndex - 1) * manualBonusItemsPerPage,
                  take: manualBonusItemsPerPage,
                }"
              >
                <template
                  v-slot="{ result: { error: manualBonusError, data: manualBonusData }, isLoading: manualBonusIsLoading }"
                >
                  <!-- Error -->
                  <AppNotification
                    v-if="manualBonusError"
                    :message="manualBonusError.message"
                  />

                  <!-- Result -->
                  <v-card
                    v-else-if="manualBonusIsLoading === 1"
                    :loading="manualBonusIsLoading === 1"
                    flat
                  />

                  <!-- Result -->
                  <v-card
                    v-else-if="manualBonusData && manualBonusData.getManualBonusHistory"
                    flat
                  >
                    <v-data-table
                      v-if="manualBonusData.getManualBonusHistory.data"
                      :headers="manualBonusHeaders"
                      :items="manualBonusData.getManualBonusHistory.data || []"
                      :items-per-page="manualBonusItemsPerPage"
                      disable-sort
                      hide-default-footer
                    >
                      <template v-slot:[`item.createdAt`]="{ item }">
                        {{ item.createdAt | moment('DD.MM.YYYY, HH:mm') }}
                      </template>
                      <template v-slot:[`item.type`]="{ item }">
                        {{ options.getManualBonusOptions.find((r) => r.id == item.type).title }}
                      </template>
                    </v-data-table>
                    <div class="text-center pt-3">
                      <v-pagination
                        v-model="manualBonusPageIndex"
                        :length="Math.ceil(manualBonusData.getManualBonusHistory.count / manualBonusItemsPerPage)"
                        :total-visible="9"
                      />
                      <v-select
                        v-model="manualBonusItemsPerPage"
                        :items="[5, 50, 500]"
                        class="pa-4 pt-5"
                        label="Строк на странице"
                        @change="manualBonusItemsPerPage"
                      />
                    </div>
                  </v-card>

                  <!-- No result -->
                  <AppNotification
                    v-else
                    message="Нет результата :("
                  />
                </template>
              </ApolloQuery>
            </template>
          </ApolloQuery>
        </v-col>
      </v-row>

      <!-- Офферы игрока      -->

      <v-row class="ma-sm-4">
        <v-col
          class="text-center text-h5"
          cols="12"
        >
          Офферы игрока
        </v-col>
        <v-col
          class="text-center text-h5"
          cols="12"
        >
          <ApolloQuery
            :query="require('../graphql/queries/getPlayerOffers.graphql')"
            :variables="{
              filters: {
                search: `${userId}`
              },
              pagination: { page: 0, limit: 100 },
              // offset: (withdrawsPageIndex - 1) * withdrawsItemsPerPage,
              // limit: withdrawsItemsPerPage,
            }"
          >
            <template v-slot="{ result: { error: playerOffersError, data: playerOffers }, isLoading: playerOffersIsLoading }">
              <!-- Error -->
              <AppNotification
                v-if="playerOffersError"
                :message="playerOffersError.message"
              />

              <!-- Result -->
              <v-card
                v-else-if="playerOffersIsLoading === 1"
                :loading="playerOffersIsLoading === 1"
                flat
              />

              <!-- Result -->
              <v-card
                v-else-if="playerOffers && playerOffers.adminGetPlayerOffers"
                flat
              >
                <v-data-table
                  v-if="playerOffers.adminGetPlayerOffers.items"
                  :headers="playerOfferHeaders"
                  :items="playerOffers.adminGetPlayerOffers.items || []"
                  :items-per-page="withdrawsItemsPerPage"
                  disable-sort
                  hide-default-footer
                >
                  <template v-slot:[`item.statusUpdatedAt`]="{ item }">
                    {{ item.statusUpdatedAt | moment('DD.MM.YYYY, HH:mm') }}
                  </template>
                </v-data-table>
              </v-card>

              <!-- No result -->
              <AppNotification
                v-else
                message="Нет результата :("
              />
            </template>
          </ApolloQuery>
        </v-col>
      </v-row>

      <!-- История выводов -->
      <v-row class="ma-sm-4">
        <v-col
          class="text-center text-h5"
          cols="12"
        >
          История выводов
        </v-col>
        <v-col class="text--lighten-1 flex-column">
          <div>Текущее время игрока: {{ timezone }}</div>
          <div>Сумма вывода: {{ mainPurseTypeWithdrawAmountToday }} {{ purseType }} </div>
        </v-col>
        <v-col
          class="text-center text-h5"
          cols="12"
        >
          <ApolloQuery
            :query="require('../graphql/queries/getWithdraws.gql')"
            :variables="{
              userId,
              offset: (withdrawsPageIndex - 1) * withdrawsItemsPerPage,
              limit: withdrawsItemsPerPage,
            }"
          >
            <template v-slot="{ result: { error: withdrawError, data: userWithdraws }, isLoading: withdrawIsLoading }">
              <!-- Error -->
              <AppNotification
                v-if="withdrawError"
                :message="withdrawError.message"
              />

              <!-- Result -->
              <v-card
                v-else-if="withdrawIsLoading === 1"
                :loading="withdrawIsLoading === 1"
                flat
              />

              <!-- Result -->
              <v-card
                v-else-if="userWithdraws && userWithdraws.getWithdraws"
                flat
              >
                <v-data-table
                  v-if="userWithdraws.getWithdraws.withdraws"
                  :headers="withdrawHeaders"
                  :items="userWithdraws.getWithdraws.withdraws || []"
                  :items-per-page="withdrawsItemsPerPage"
                  disable-sort
                  hide-default-footer
                >
                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ item.createdAt | moment('DD.MM.YYYY, HH:mm') }}
                  </template>
                </v-data-table>
                <div class="text-center pt-3">
                  <v-pagination
                    v-model="withdrawsPageIndex"
                    :length="Math.ceil(userWithdraws.getWithdraws.count / withdrawsItemsPerPage)"
                    :total-visible="9"
                  />
                  <v-select
                    v-model="withdrawsItemsPerPage"
                    :items="[5, 50, 500]"
                    class="pa-4 pt-5"
                    label="Строк на странице"
                    @change="withdrawsItemsPerPage"
                  />
                </div>
              </v-card>

              <!-- No result -->
              <AppNotification
                v-else
                message="Нет результата :("
              />
            </template>
          </ApolloQuery>
        </v-col>
      </v-row>
      <!-- Модалка. Подробности матча -->
      <v-dialog
        v-model="betsDialog"
        max-width="1200px"
        persistent
      >
        <!--        <template v-slot:activator="{ on, attrs }">-->
        <!--          <v-btn-->
        <!--            v-bind="attrs"-->
        <!--            class="text-none"-->
        <!--            depressed-->
        <!--            height="36"-->
        <!--            large-->
        <!--            v-on="on"-->
        <!--          >-->
        <!--            Подробнее-->
        <!--          </v-btn>-->
        <!--        </template>-->
        <v-card>
          <v-card-title>
            <span class="text-h5">Подробности матча</span>
          </v-card-title>
          <v-data-table
            v-if="betsDialogData"
            :headers="betsModalHeaders"
            :items="betsDialogData"
            :items-per-page="30"
            disable-sort
            hide-default-footer
          >
            <template v-slot:[`item.t`]="{ item }">
              {{ item.t | moment('DD.MM.YYYY, HH:mm') }}
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer />

            <v-btn
              color="primary"
              text
              @click="betsDialog = false"
            >
              Закрыть
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Модалка. Подробности бэтбай ставки -->
      <v-dialog
        v-model="bbetsDialog"
        max-width="1200px"
        persistent
      >
        <!--        <template v-slot:activator="{ on, attrs }">-->
        <!--          <v-btn-->
        <!--            v-bind="attrs"-->
        <!--            class="text-none"-->
        <!--            depressed-->
        <!--            height="36"-->
        <!--            large-->
        <!--            v-on="on"-->
        <!--          >-->
        <!--            Подробнее-->
        <!--          </v-btn>-->
        <!--        </template>-->
        <v-card>
          <v-card-title>
            <span class="text-h5">Подробности матча</span>
          </v-card-title>
          <v-data-table
            v-if="bbetsDialogData"
            :headers="bbetsModalHeaders"
            :items="bbetsDialogData"
            :items-per-page="30"
            disable-sort
            hide-default-footer
          >
            <template v-slot:[`item.matchTime`]="{ item }">
              {{ new Date(item.matchTime) | moment('DD.MM.YYYY, HH:mm') }}
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer />

            <v-btn
              color="primary"
              text
              @click="bbetsDialog = false"
            >
              Закрыть
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </AppRow>
</template>

<script>
import AppRow from '@/components/AppRow.vue';
import AppNotification from '@/components/AppNotification.vue';
import getUserInfo from '@/graphql/queries/getUserInfo.gql';
import getUserDeposits from '@/graphql/queries/getUserDeposits.gql';
import getManualBonusHistory from '@/graphql/queries/getManualBonusHistory.gql';
import getUserKycStatus from '@/graphql/queries/getUserKycStatus.gql';
import getTagsRequest from '@/graphql/queries/getTags.gql';

export default {
  components: {
    AppRow,
    AppNotification,
  },
  data() {
    return {
      user: {},
      items: [
        {
          text: 'Имя',
          value: 'username',
        },
        {
          text: 'Роль',
          value: 'role',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Номер телефона',
          value: 'mobilePhone',
        },
        {
          text: 'Дата регистрации',
          value: 'createdAt',
        },
        {
          text: 'User ID',
          value: 'userId',
        },
        {
          text: 'Partner Type',
          value: 'partnerType',
        },
        {
          text: 'Click Id',
          value: 'leadId',
        },
        {
          text: 'IP адрес',
          value: 'registerIp',
        },
      ],
      secondItems: [
        {
          text: 'Привязанные соц. сети',
          value: 'socialAccounts',
        },
        {
          text: 'SC',
          value: 'scoreCounter',
        },
        {
          text: 'Max Bet',
          value: 'maxBet',
        },
        {
          text: 'Доп. инфа',
          value: 'meta',
        },
        {
          text: 'Бан',
          value: 'banned',
        },
        {
          text: 'KYC',
          value: 'kycVerified',
        },
        {
          text: 'Статус',
          value: 'status',
        },
        {
          text: 'Причина',
          value: 'statusReason',
        },
        {
          text: 'Теги',
          value: 'tags',
        },
      ],
      links: [
        {
          link: 'https://vk.com/id',
          icon: 'mdi-vk',
          value: 'vk',
        },
        {
          link: 'https://steamcommunity.com/profiles/',
          icon: 'mdi-steam',
          value: 'steam',
        },
        {
          link: 'https://t.me/',
          icon: 'mdi-telegram',
          value: 'telegram',
        },
      ],
      scMap: {
        WITHDRAW_ACCOUNT_NUMBER: 'Аккаунт вывода',
        PHONE: 'Телефон',
        EMAIL: 'Почта',
        IP: 'IP',
      },
      headers: [
        { text: 'ID:', value: 'id', class: 'white--text text-subtitle-2' },
        { text: 'Режим:', value: 'gameId', class: 'white--text text-subtitle-2' },
        { text: 'Дата:', value: 'gameTimestamp', class: 'white--text text-subtitle-2' },
        { text: 'Сумма:', value: 'betAmount', class: 'white--text text-subtitle-2' },
        { text: 'Валюта:', value: 'purseType', class: 'white--text text-subtitle-2' },
        { text: 'Результат:', value: 'result', class: 'white--text text-subtitle-2' },
        { text: 'Выиграл:', value: 'win', class: 'white--text text-subtitle-2' },
        { text: 'Баланс до выигрыша:', value: 'balanceBeforeCredit', class: 'white--text text-subtitle-2' },
      ],
      betsHeaders: [
        { text: 'CouponId:', value: 'couponId', class: 'white--text text-subtitle-2' },
        { text: 'Коэфф:', value: 'K', class: 'white--text text-subtitle-2' },
        { text: 'Сумма:', value: 'betAmount', class: 'white--text text-subtitle-2' },
        { text: 'Выигрыш:', value: 'winAmount', class: 'white--text text-subtitle-2' },
        { text: 'Статус:', value: 'status', class: 'white--text text-subtitle-2' },
        { text: 'Валюта:', value: 'purseType', class: 'white--text text-subtitle-2' },
        { text: 'Тип:', value: 'type', class: 'white--text text-subtitle-2' },
        { text: 'Дата:', value: 'createdAt', class: 'white--text text-subtitle-2' },
        { text: 'Матч:', value: 'bets', class: 'white--text text-subtitle-2' },
        { text: '', value: 'action', align: 'center' },
      ],
      betsModalHeaders: [
        { text: 'ID:', value: 'id', class: 'white--text text-subtitle-2' },
        { text: 'Название:', value: 'title', class: 'white--text text-subtitle-2' },
        { text: 'Коэфф:', value: 'K', class: 'white--text text-subtitle-2' },
        { text: 'Дата:', value: 't', class: 'white--text text-subtitle-2' },
        { text: 'Турнир:', value: 'tnm', class: 'white--text text-subtitle-2' },
        { text: 'Статус:', value: 'status', class: 'white--text text-subtitle-2' },
        { text: 'Страна:', value: 'eventCountry', class: 'white--text text-subtitle-2' },
        { text: 'Игра:', value: 'eventName', class: 'white--text text-subtitle-2' },
        { text: 'Исход:', value: 'bn', class: 'white--text text-subtitle-2' },
      ],
      bbetsHeaders: [
        { text: 'CouponId:', value: 'couponId', class: 'white--text text-subtitle-2' },
        { text: 'Коэфф:', value: 'K', class: 'white--text text-subtitle-2' },
        { text: 'Сумма:', value: 'betAmount', class: 'white--text text-subtitle-2' },
        { text: 'Выигрыш:', value: 'winAmount', class: 'white--text text-subtitle-2' },
        { text: 'Статус:', value: 'status', class: 'white--text text-subtitle-2' },
        { text: 'Валюта:', value: 'purseType', class: 'white--text text-subtitle-2' },
        { text: 'Тип:', value: 'type', class: 'white--text text-subtitle-2' },
        { text: 'Дата:', value: 'createdAt', class: 'white--text text-subtitle-2' },
        { text: '', value: 'action', align: 'center' },
      ],
      bbetsModalHeaders: [
        { text: 'Маркет:', value: 'marketName', class: 'white--text text-subtitle-2' },
        { text: 'Команды:', value: 'competitorName', class: 'white--text text-subtitle-2' },
        { text: 'Турнир:', value: 'tournamentName', class: 'white--text text-subtitle-2' },
        { text: 'Дата:', value: 'matchTime', class: 'white--text text-subtitle-2' },
        { text: 'Страна:', value: 'categoryName', class: 'white--text text-subtitle-2' },
        { text: 'Вид спорта:', value: 'sportName', class: 'white--text text-subtitle-2' },
        { text: 'Коэфф:', value: 'odds', class: 'white--text text-subtitle-2' },
        { text: 'Статус:', value: 'status', class: 'white--text text-subtitle-2' },
        { text: 'Исход:', value: 'outcomeName', class: 'white--text text-subtitle-2' },
      ],
      depositHeaders: [
        { text: 'ID:', value: 'id', class: 'white--text text-subtitle-2' },
        { text: 'Транзакция:', value: 'transactionId', class: 'white--text text-subtitle-2' },
        { text: 'Метод:', value: 'payway', class: 'white--text text-subtitle-2' },
        { text: 'Сумма:', value: 'amount', class: 'white--text text-subtitle-2' },
        { text: 'Валюта:', value: 'currency', class: 'white--text text-subtitle-2' },
        { text: 'Статус:', value: 'status', class: 'white--text text-subtitle-2' },
        { text: 'Дата:', value: 'createdAt', class: 'white--text text-subtitle-2' },
      ],
      withdrawHeaders: [
        { text: 'ID:', value: 'id', class: 'white--text text-subtitle-2' },
        { text: 'Сумма:', value: 'amount', class: 'white--text text-subtitle-2' },
        { text: 'Валюта:', value: 'purseType', class: 'white--text text-subtitle-2' },
        { text: 'Метод:', value: 'walletType', class: 'white--text text-subtitle-2' },
        { text: 'Статус:', value: 'status', class: 'white--text text-subtitle-2' },
        { text: 'Дата:', value: 'createdAt', class: 'white--text text-subtitle-2' },
      ],
      playerOfferHeaders: [
        { text: 'ID:', value: 'id', class: 'white--text text-subtitle-2' },
        { text: 'Статус оффера:', value: 'status', class: 'white--text text-subtitle-2' },
        { text: 'Тип активации:', value: 'offerActivationType', class: 'white--text text-subtitle-2' },
        { text: 'Сумма оффера:', value: 'rewardAmount', class: 'white--text text-subtitle-2' },
        { text: 'КФ вейджера:', value: 'offerWager', class: 'white--text text-subtitle-2' },
        { text: 'Сколько отыграно средств:', value: 'currentWagerAmount', class: 'white--text text-subtitle-2' },
        { text: 'Дата обновления статуса:', value: 'statusUpdatedAt', class: 'white--text text-subtitle-2' },
      ],
      selectedPurseType: null,
      balanceReal: 0,
      balanceBonus: 0,
      purseTypes: [
        'INR',
        'BTC',
        'USD',
        'EUR',
        'ETC',
        'UAH',
        'RUB',
        'LTC',
        'DEMO',
        'ETH',
        'BRL',
        'BCH',
        'BNB',
        'DASH',
        'DOGE',
        'TRX',
        'USDT',
        'XMR',
        'ZEC',
        'XRP',
        'KZT',
        'VND',
        'UZS',
        'IDR',
        'AZN',
        'KGS',
        'PKR',
        'BDT',
        'CLP',
        'PEN',
        'COP',
        'MXN',
        'PYG',
        'UYU',
        'BOB',
        'CRC',
        'GTQ',
        'PAB',
        'NIO',
        'ARS',
        'SVC',
        'NGN',
      ],
      timezone: '',
      mainPurseTypeWithdrawAmountToday: '',
      gameMode: null,
      gameModes: ['UNDEFINED_GAME_MODE', 'CRASH', 'DICE', 'MINES', 'DOUBLE', 'TOWER', 'JACKPOT'],
      role: null,
      email: null,
      usernameField: null,
      roles: ['USER', 'ADMINISTRATOR', 'BLOGGER', 'PRODUCTION', 'CASHUP', 'PRODUCTION_TWITCH', 'TEST', 'BANNED'],
      statuses: ['NONE', 'FREEZE', 'FULL_FREEZE', 'STOP', 'BAN', 'PERMANENT'],
      status: null,
      statusReason: '',
      dateFrom: '',
      dateTo: '',
      dialog: false,
      isChangeBalanceOpened: false,
      dialogUserName: false,
      dialogTransfer: false,
      scDialog: false,
      depositDialog: false,
      manualBonusDialog: false,
      betsDialog: false,
      bbetsDialog: false,
      betsDialogData: null,
      bbetsDialogData: null,
      depositAmount: 0.0,
      offerId: '',
      pageIndex: 1,
      itemsPerPage: 5,
      betsPageIndex: 1,
      betsItemsPerPage: 5,
      bbetsPageIndex: 1,
      bbetsItemsPerPage: 5,
      withdrawsPageIndex: 1,
      withdrawsItemsPerPage: 5,
      manualBonusPageIndex: 1,
      manualBonusItemsPerPage: 5,
      manualBonusAmount: 0,
      manualBonusType: null,
      cards: [
        {
          text: 'Реальный баланс',
          value: 'balanceReal',
        },
      ],
      show: false,
      showReal: false,
      showRtpCard: false,
      showBetsCard: false,
      pass: '***',
      balances: [
        {
          text: 'Промокоды',
          value: 'promocodes',
        },
        {
          text: 'Бонусный слот',
          value: 'bonusSlot',
        },
        {
          text: 'Викторина в чате',
          value: 'chatPrises',
        },
        {
          text: 'Денежный дождь',
          value: 'moneyRain',
        },
      ],
      stats: [
        {
          key: 'deposits',
          name: 'Депозиты',
          count: 'depositCount',
          amount: 'depositAmount',
        },
        {
          key: 'withdraws',
          name: 'Выводы',
          count: 'withdrawsCount',
          amount: 'withdrawsAmount',
        },
        {
          key: 'bonuses',
          name: 'Бонусы',
          count: 'bonusCount',
          amount: 'bonusAmount',
        },
      ],
      bets: [
        {
          text: 'Доход (NGR)',
          value: 'ngr',
        },
        {
          text: 'RTP',
          value: 'gamesInfo',
        },
        {
          text: 'Сумма промокодов',
          value: 'promocodes',
        },
        {
          text: 'Сделано ставок',
          value: 'betsAmount',
        },
        {
          text: 'Сумма отыгрыша депозита',
          value: 'depositWagering',
        },
      ],
      message: '',
      from: '',
      reasonCategory: '',
      status2fa: null,
      selectReason: [
        { text: 'Совпадают кошельки депозитов на 2-х и более аккаунтах', key: 'DEPOSIT_WALLET' },
        { text: 'Совпадают кошельки выводов на 2-х и более аккаунтах', key: 'WITHDRAW_WALLET' },
        { text: 'Совпадает ip-адрес на 2-х и более аккаунтах', key: 'IP' },
        { text: 'Совпадают регистрационные данные на 2-х и более аккаунтах', key: 'INFO' },
        { text: 'Совпадающий FingerPrint (отпечаток железа)', key: 'DEVICE' },
      ],
      selectDepositStatus: '',
      statusDeposit: [
        { text: 'Неопределенный статус', key: '0' },
        { text: 'Создан', key: '1' },
        { text: 'Проведен', key: '2' },
        { text: 'Ошибка', key: '3' },
      ],
      headersAntifraud: [
        { text: 'ID:', value: 'id', class: 'white--text text-subtitle-2' },
        { text: 'Ник:', value: 'username', class: 'white--text text-subtitle-2' },
        { text: 'Баланс:', value: 'balance', class: 'white--text text-subtitle-2' },
        { text: 'Дата регистрации:', value: 'registrationAt', class: 'white--text text-subtitle-2' },
        { text: 'Депозитов:', value: 'deposits', class: 'white--text text-subtitle-2' },
        { text: 'Выводов:', value: 'withdraws', class: 'white--text text-subtitle-2' },
        { text: 'Причины:', value: 'reasons', class: 'white--text text-subtitle-2' },
        { text: '', value: 'action', align: 'center' },
      ],
      manualBonusHeaders: [
        { text: 'ID:', value: 'id', class: 'white--text text-subtitle-2' },
        { text: 'Тип:', value: 'type', class: 'white--text text-subtitle-2' },
        { text: 'Сумма:', value: 'amount', class: 'white--text text-subtitle-2' },
        { text: 'Дата:', value: 'createdAt', class: 'white--text text-subtitle-2' },
      ],
      docOpened: false,
      docUrl: '',
      maxBet: null,
      secondUserId: null,
      varTransfer: 'get',
      selectedChangeBalanceType: null,
      selectedChangeManualBalanceType: null,
      changeBalanceComment: '',
      newBalance: 0,
      changeBalanceTypeItems: [
        'Уменьшить баланс',
        'Увеличить баланс',
      ],

      changeBalanceState: {
        loading: false,
        error: null,
      },
      tagsStateIsChanging: false,
      tagsWindowOpened: false,
      selectedTagId: [],
      manualTags: [],
      sortedTags: [],
    };
  },
  computed: {
    getTwoFaStatus() {
      const status = this.status2fa;
      if (!status) return null;

      if (status === 'ACTIVATED') {
        return 'Включена';
      }
      if (status === 'DEACTIVATED') {
        return 'Выключена';
      }
      if (status === 'NOT_SET') {
        return 'Не установлена';
      }
      return null;
    },
    manualBalanceIncreaseType() {
      return {
        TECHNICAL: 'TECHNICAL',
        NORMAL: 'NORMAL',
      };
    },
    manualBalanceDecreaseType() {
      return {
        // WITHDRAW: 'WITHDRAW',
        OTHER: 'OTHER',
        CORRECTION: 'CORRECTION',
        // PERMANENT: 'PERMANENT',
      };
    },
    manualBalanceChangeItems() {
      if (this.selectedChangeBalanceType === 'Уменьшить баланс') {
        return Object.keys(this.manualBalanceDecreaseType);
      } if (this.selectedChangeBalanceType === 'Увеличить баланс') {
        return Object.keys(this.manualBalanceIncreaseType);
      }
      return null;
    },
    userId() {
      return +this.$route.params.id;
    },
  },
  watch: {
    user(value) {
      if (value.getUserInfo) {
        this.status2fa = value.getUserInfo.status2FA;
        this.username = value.getUserInfo.username;
        this.role = value.getUserInfo.role;
        this.email = value.getUserInfo.email;
        this.maxBet = value.getUserInfo.maxBet;
        this.status = value.getUserInfo.status;
        this.statusReason = value.getUserInfo.statusReason;
        this.mainPurseTypeWithdrawAmountToday = value.getUserInfo.mainPurseTypeWithdrawAmountToday;
        this.purseType = value.getUserInfo.purseType;
        this.manualTags = value.getUserInfo.tags.manualTags;
        if (value.getUserInfo.timezone) {
          const userDate = new Date().toLocaleString('en-US', { timeZone: value.getUserInfo.timezone, hour12: false });
          const formattedTime = userDate.split(' ')[1].substr(0, 5);
          this.timezone = formattedTime;
        }
      }
    },
  },
  mounted() {
    this.changedTagsStructure();
  },
  methods: {
    resetChangeBalanceForm() {
      this.selectedChangeBalanceType = null;
      this.newBalance = 0;
      this.selectedPurseType = null;
      this.changeBalanceComment = '';
      this.selectedChangeManualBalanceType = null;
    },
    refetchChangeBalanceQueries() {
      this.isChangeBalanceOpened = false;
      this.dialogUserName = false;
      this.dialogTransfer = false;
      this.resetChangeBalanceForm();
      return [{
        query: getUserInfo,
        variables: {
          userId: this.userId,
        },
      }];
    },
    async  balanceDecrement() {
      this.changeBalanceState = {
        loading: true,
        error: false,
      };
      try {
        await this.$apollo.mutate({
          // eslint-disable-next-line global-require
          mutation: this.selectedChangeBalanceType === 'Уменьшить баланс' ? require('../graphql/mutations/decreaseBalance.gql') : require('../graphql/mutations/increaseBalance.gql'),
          variables: {
            userId: this.userId,
            purseType: this.selectedPurseType,
            amount: this.newBalance,
            comment: this.changeBalanceComment,
            type: this.selectedChangeManualBalanceType,
          },
          // eslint-disable-next-line global-require
          // query: require('../graphql/queries/adminBetbyBetsHistory.gql'),
          refetchQueries: this.refetchChangeBalanceQueries,
        });
      } catch (e) {
        this.changeBalanceState.error = e;
      } finally {
        this.changeBalanceState.loading = false;
      }
    },
    balanceIncrement() {

    },
    /* eslint-disable */
    moneySourcesInfos(data) {
      const obj = { ...data.getUserInfo.moneySourcesInfos[0] };
      delete obj['__typename'];
      delete obj.purseType;
      delete obj.diff;
      delete obj.expected_balance;
      Object.assign(obj, { expected_balance: 1, diff: 1 });
      return [...Object.keys(obj)];
    },
    async getInfo(queryType) {
      const url = `https://${process.env.VUE_APP_BASE_URL}/${queryType}-history/download`
      const headers = {
        "Content-Type": "application/json",
        authorization: localStorage.getItem('token')
      };
      const body = JSON.stringify({
        userId: this.userId,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      });
      const today = new Date();
      const date = today.toISOString().split('T')[0]
      const name = `${this.userId}_${queryType}s_${date}`
      const format = 'xlsx'
      try {
        const res = await fetch(url, {
          method: 'POST',
          headers,
          body
        });
        const blob = await res.blob();
        const newBlob = new Blob([blob]);
        const blobUrl = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', `${name}.${format}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        // clean up Url
        window.URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.log(error);
      }
    },
    /* eslint-enable */
    upd(url) {
      this.docOpened = !this.docOpened;
      this.docUrl = url;
    },
    goToUserFromSc(userId) {
      this.scDialog = false;
      this.$router.push(`/users/${userId}`);
    },
    newPass(store, { data }) {
      if (data && data.resetUserPass && data.resetUserPass.pass) {
        this.$data.pass = data.resetUserPass.pass;
      }
    },
    update2faStatus(store, { data }) {
      if (data && data.adminDelete2FA && data.adminDelete2FA.status) {
        this.$data.status2fa = data.adminDelete2FA.status;
      }
    },
    showBetsModal(data) {
      this.$data.betsDialog = true;
      this.$data.betsDialogData = data;
    },
    showBbetsModal(data) {
      this.$data.bbetsDialog = true;
      this.$data.bbetsDialogData = data;
    },
    initStatusDeposit(status) {
      switch (status) {
        case 0:
          return 'UNDEFINED';
        case 1:
          return 'CREATED';
        case 2:
          return 'SUCCESS';
        case 3:
          return 'ERROR';
        default:
          return 'UNDEFINED';
      }
    },
    changeSelectedPurseType(value) {
      const balanceData = this.user.getUserInfo.balance
        .find(item => item.purseType === value);
      if (balanceData) {
        this.balanceReal = balanceData.amount;
        this.balanceBonus = balanceData.bonus;
      } else {
        this.balanceReal = 0;
        this.balanceBonus = 0;
      }
    },
    firstPage() {
      this.page = 1;
    },
    saveDates(dates) {
      [this.dateFrom, this.dateTo] = dates;
    },
    onNotification() {
      this.message = '';
      this.from = '';
    },
    refetchQueriesAfterMyMutation() {
      this.dialog = false;
      this.dialogUserName = false;
      this.dialogTransfer = false;
      return [{
        query: getUserInfo,
        variables: {
          userId: this.userId,
        },
      }];
    },
    refetchDepositsAfterMyMutation() {
      this.depositDialog = false;
      return [{
        query: getUserDeposits,
        variables: {
          userId: this.userId,
        },
      }];
    },
    refetchManualBonusAfterMyMutation() {
      this.manualBonusDialog = false;
      return [{
        query: getManualBonusHistory,
        variables: {
          userId: this.userId,
          skip: (this.manualBonusPageIndex - 1) * this.manualBonusItemsPerPage,
          take: this.manualBonusItemsPerPage,
        },
      }];
    },
    async changedTagsStructure() {
      try {
        const result = await this.$apollo.query({ query: getTagsRequest, variables: {} });
        if (result.data.getTags) {
          const sortedObj = result.data.getTags.tags.reduce((acc, item) => {
            if (!acc[item.category]) {
              acc[item.category] = [item];
            } else {
              acc[item.category].push(item);
            }
            return acc;
          }, {});

          this.sortedTags = Object.entries(sortedObj).reduce((acc, item) => {
            const transformedItem = item.flat().map(element => (typeof element === 'string' ? { header: element } : {
              text: element.name, value: element, id: element.id, category: element.category,
            }));
            acc.push(transformedItem);
            return acc.flat();
          }, []);
        }
      } catch (e) {
        console.log('changedTagsStructure', e);
      }
    },
    refetchGetUserKycStatus() {
      return [{
        query: getUserKycStatus,
        variables: {
          userId: this.userId,
        },
      }];
    },
    async handleTagsChange(value) {
      const manualTagsCategories = this.manualTags.map(tag => tag.category);
      const filteredTags = value.filter(item => !manualTagsCategories.includes(item.category));
      const isTagInManualTags = this.manualTags.map(tag => tag.id).includes(value[0].id);
      if (isTagInManualTags) {
        this.tagsStateIsChanging = true;
        await this.removeTag();
        this.tagsStateIsChanging = false;
      }

      this.selectedTagId = filteredTags;
      if (this.selectedTagId.length) {
        this.tagsStateIsChanging = true;
        await this.tagAdd();
        this.tagsStateIsChanging = false;
      }
    },
    async tagAdd() {
      try {
        await this.$apollo.mutate({
          // eslint-disable-next-line global-require
          mutation: require('../graphql/mutations/adminAddUserTag.gql'),
          variables: {
            userId: this.userId,
            tagId: this.selectedTagId[0].id,
          },
          refetchQueries: this.refetchQueriesAfterMyMutation,
        });
        this.selectedTagId = [];
      } catch (e) {
        this.changeBalanceState.error = e;
      }
    },
    async removeTag() {
      await this.$apollo.mutate({
        // eslint-disable-next-line global-require
        mutation: require('../graphql/mutations/adminRemoveUserTag.gql'),
        variables: {
          userId: this.userId,
          tagId: this.selectedTagId[0].id,
        },
        refetchQueries: this.refetchQueriesAfterMyMutation,
      });
    },
  },

};
</script>

<style>
.f {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: unset;
}

.f>div {
  flex: unset;
}

.ttc {
  text-transform: capitalize;
}
</style>
